import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorStylist = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin: 30px 0;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const StyledLink = styled.a`
  display: block;
  margin-bottom: 30px;
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorStylistContentPutten = ({ designScrollRef, onClick }) => (
  <StyledInteriorStylist ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Interieurstylist-putten.jpeg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieurstylist Putten</Title>
          <StyledParagraph>
            Ben je gevestigd in Putten en zoek je professionele begeleiding bij het stylen van je interieur? Mijn naam is Marjella. Vanuit mijn bedrijf Livaun werk ik sinds 2018 als interieurstylist in Putten.
          </StyledParagraph>
        </Item>
        <Tablet>
          <Image src="/img/Interieurstylist-putten.jpeg" />
        </Tablet>
        <Item>
          <SubTitle>Wat voegt een interieurstylist toe?</SubTitle>
          <StyledParagraph>
            Beschikt je woning over een sterke basis, maar ontbreekt het aan stijl en warmte? Ik help je als interieurstylist in Putten bij het kiezen van kleuren, materialen, meubels en accessoires om jouw interieur helemaal af te maken.
            <br />
            <br />
            Als interieurstylist staan jouw persoonlijke wensen en levensstijl centraal in mijn aanpak. Hierdoor kan ik de inrichting perfect laten aansluiten op jouw voorkeuren, stijl en persoonlijkheid. Bij Livaun is het uiteindelijke doel dat je interieur voelt als 'eigen en vertrouwd'. Ik beantwoord al je vragen die je hebt rondom het interieur en geef tips en praktische ideeën, zodat je zelf aan de slag kunt en met vertrouwen veranderingen kunt aanbrengen!
            <br />
            <br />
            Door middel van bijvoorbeeld een moodboard of shoppingpagina kan ik een duidelijk plan voor je maken. Het moodboard richt zich op stijl, sfeer, kleuren en materialen, terwijl de shoppingpagina praktische product adviezen biedt op basis van het moodboard. Zo kun je gemakkelijk de meubels en decoraties kiezen die bij je passen. Wil je ook je indeling aanpassen of verbeteren? En gelijk nadenken over een goed lichtplan. Ook dat is natuurlijk mogelijk!
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
        <Item>
          <SubTitle>Mijn werk als interieurstylist in Putten</SubTitle>
          <StyledParagraph>
            Met Livaun bied ik zowel <InlineLink href="/">advies aan huis</InlineLink> met aanvullende uitwerkingen als interieuradvies tijdens (ver)bouwen.
            <br />
            <br />
            Mijn doel als interieurstylist in Putten is niet alleen om vragen die je hebt te beantwoorden, maar ook om je te inspireren en je tips te geven om net een stapje meer te zetten. Wil je na het gesprek toch wat meer houvast? Naar wens kan ik een moodboard, shoppingpagina, 2D plattegrond of lichtplan voor je uitwerken. Hierin laat ik zien hoe je jouw interieur zelfstandig en op een toegankelijke manier kunt verbeteren.
          </StyledParagraph>
        </Item>
        <Item>
          <SubTitle>Verbouwen in Putten?</SubTitle>
          <StyledParagraph>
            Als je gaat (ver)bouwen en vanaf het begin rekening wilt houden met het interieur, bied ik uitgebreid advies. Dit is mogelijk wanneer je ten minste één etage gaat aanpakken. Gaat het om bijvoorbeeld een badkamer, dan kan ik je helpen vanuit een advies aan huis met extra uitwerkingen. Samen overleggen we over de optimale indeling, materiaalkeuzes, verlichting en natuurlijk de uiteindelijke inrichting. Door mij als interieurstylist vroeg bij je project te betrekken, zorgen we ervoor dat elke beslissing in het bouwproces zorgvuldig is afgestemd op het interieurontwerp. Ik breng in kaart wat er momenteel ontbreekt in je leefomgeving, ga na welke behoeften er zijn en breng je grootste wensen in beeld. Zo bouwen we samen aan jouw droomhuis in Putten dat volledig aansluit bij jouw manier van leven.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorStylist>
);

export default InteriorStylistContentPutten;
