import React from "react";
import styled from "styled-components";
import Paragraph from "../atoms/Paragraph";
import Responsive from "react-responsive";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 70px 20px;

  @media only screen and (min-width: 992px) {
    padding: 100px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: block;
  font-size: 38px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: white;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3rem;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: white;
  font-size: 18px;
  line-height: normal;

  @media only screen and (min-width: 992px) {
    max-width: 450px;
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  max-width: 198px;
  height: 60px;
  padding: 0 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 150px;

  @media only screen and (min-width: 992px) {
    width: 50%;
  }
`;

const Image = styled.img`
  display: none;
  width: 380px;
  height: auto;

  @media only screen and (min-width: 992px) {
    display: block;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: auto;
  }
`;

const RightSide = styled.div`
  width: 100%;
  background-color: #cfc6ba;
  
  @media only screen and (min-width: 992px) {
      width: 50%;
  }
`;

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
const Desktop = props => <Responsive {...props} minWidth={992} />;

const Vision = ({ onClick, visionScrollRef }) => (
  <>
    <Wrapper ref={visionScrollRef}>
      <LeftSide>
        <Image src="/img/livaun-marjella-mijnten-interieur.jpg" />
      </LeftSide>
      <RightSide>
        <TextWrapper>
          <Title>Mijn visie</Title>
          <StyledParagraph>
            Als stylist kijk ik veel naar de toekomst en nieuwe ontwikkelingen. We leven in een wereld waar alles voorhanden is. Maar helaas komt dit met een prijs. Bewust omgaan met materialen vind ik daarom super belangrijk.
            <br />
            <br />
            Een kast uit je studententijd, kunstwerk met emotionele waarde of een lamp uit de kringloopwinkel. Er afstand van doen is in veel gevallen helemaal niet nodig! Soms is een andere setting of een likje verf genoeg. En daarnaast zijn het juist deze items die zorgen voor een persoonlijk interieur waarin jij je helemaal thuis voelt. Slimme oplossingen en hergebruik zorgen namelijk voor verrassing en eigenheid.
            <br />
            <br />
            Ik wil laten zien dat een ‘nieuw’ interieur niet altijd veel geld hoeft te kosten. Daarnaast vind ik dat een interieur ook gewoon lekker praktisch en leefbaar moet zijn. In de basis kijk ik altijd naar wat er al is en hoe dat eventueel  hergebruikt kan worden. Voor items waar je afstand van wilt doen of gewoonweg aan vervanging toe zijn zoek ik naar wens passende alternatieven.
          </StyledParagraph>
          <StyledButton onClick={onClick}>Neem contact op</StyledButton>
        </TextWrapper>
      </RightSide>
    </Wrapper>
  </>
);

export default Vision;
