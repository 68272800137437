import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorStylist = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin: 30px 0;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorDesignContentPutten = ({ designScrollRef, onClick }) => (
  <StyledInteriorStylist ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Livaun-interieurontwerp-putten.jpg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieurontwerp Putten</Title>
          <StyledParagraph>
            Een goed doordacht interieurontwerp in Putten maakt het mogelijk om al jouw ideeën en wensen in beeld te brengen. Denk aan inspirerende moodboards die sfeer en stijl voor jouw interieur weergeven, zorgvuldig geselecteerde kleuren en materialen en gedetailleerde 2D plattegronden die de indeling en verlichting van je ruimte optimaliseren.
            <br />
            <br />
            Het kan een uitdaging zijn om een helder beeld te krijgen van hoe je wensen samenkomen in een totaal interieurontwerp, vooral bij grootschalige aanpassingen aan je woning. Woon je in Putten of in de nabije omgeving en ben je van plan je huis opnieuw in te richten of te renoveren?
          </StyledParagraph>
        </Item>
        <Tablet>
          <Image src="/img/Livaun-interieurontwerp-putten.jpg" />
        </Tablet>
        <Item>
          <SubTitle>Interieurontwerp voor de Doe-Het-Zelver in Putten</SubTitle>
          <StyledParagraph>
            Met een persoonlijk advies aan huis bied ik niet alleen inspiratie voor een sfeervolle leefomgeving, maar help ik ook bij het bedenken van praktische oplossingen voor de dingen waar je tegenaan loopt. Het is mijn doel om je met een interieurontwerp in korte tijd te inspireren en je zintuigen zodanig te prikkelen dat je met de juiste tools zelf aan de slag kunt gaan. Wanneer  je behoefte hebt aan meer ondersteuning, kan ik met een op maat gemaakt moodboard, shoppingpagina, 2D indelingsplan of lichtplan, laten zien hoe je jouw interieur zelfstandig en op een toegankelijke manier kunt aanpakken!
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
        <Item>
          <SubTitle>Interieurontwerp bij (ver)bouwen in Putten</SubTitle>
          <StyledParagraph>
            Het visualiseren van een interieurontwerp tijdens de planningsfase kan overweldigend zijn door de vele mogelijkheden en keuzes die je moet maken. Vaak kies je daarom voor de veilige, meer standaard opties: veel voorkomende indelingen, een aantal lichtpunten in het midden van de ruimte en neutrale materialen en kleuren, omdat je denkt dat er dan minder fout kan gaan. Dit is een gemiste kans, vooral wanneer je vanaf de grond af aan kunt beginnen met het ontwerpen van je eigen droomhuis. Ik ondersteun je met een interieurontwerp bij het maken van dit soort keuzes en geef advies over bijvoorbeeld de voor- en nadelen, zodat we samen een helder plan kunnen maken.
            <br />
            <br />
            Een professioneel <InlineLink href="/">interieurontwerp</InlineLink> kan de sleutel zijn om dit proces te vereenvoudigen. Samen ontwikkelen we voor jouw woning in Putten een plan dat naadloos aansluit bij jouw persoonlijke wensen, gezinssituatie, levensstijl en uiteraard je eigen unieke stijl. Elementen zoals de indeling, lichtinval, kleur- en materiaalgebruik spelen een cruciale rol in hoe een ruimte wordt ervaren. Twijfel je of een bepaalde muur verwijderd moet worden of niet? Ik denk graag met je mee en werk indien nodig samen met een aannemer of architect om het ontwerp te perfectioneren. Het achteraf moeten doen van aanpassingen in een interieurontwerp  is niet alleen onpraktisch, maar kan ook onnodig kostbaar zijn.
            <br />
            <br />
            Heb je onlangs een nieuw huis gekocht in Putten of sta je op het punt om een verbouwing te beginnen? Ik help je graag! Neem contact op om de mogelijkheden voor jouw interieurontwerp te bespreken, zodat we samen jouw wensen kunnen realiseren.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorStylist>
);

export default InteriorDesignContentPutten;
