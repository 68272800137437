import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorAdviceContent = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;
  
  &:last-child {
    margin: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin: 30px 0;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorAdviceContentHarderwijk = ({ designScrollRef, onClick }) => (
  <StyledInteriorAdviceContent ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Livaun-interieuradvies-harderwijk.jpg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieuradvies Harderwijk</Title>
          <StyledParagraph>
            Bij Livaun verzorg ik, Marjella, persoonlijk interieuradvies in Harderwijk en omgeving. Ongeacht of je voorkeur uitgaat naar een landelijke, moderne, Scandinavische, of vintage stijl, minimalistisch of kleurrijk; elk advies wordt op maat gemaakt om aan te sluiten bij jouw unieke wensen en stijl.
            <br />
            <br />
            Als interieurstylist ben ik altijd vooruit aan het kijken en volg ik de nieuwste trends. Duurzaamheid is in <InlineLink href="/">interieuradvies</InlineLink> voor mij erg belangrijk, vooral in een wereld waar alles voorhanden is. Ik geloof niet dat een nieuw interieur per se nieuwe items vereist. Daarom focus ik ook graag op het hergebruiken van goede, bestaande spullen.
            <br/>
            <br/>
            Een andere indeling of een nieuwe verflaag kan wonderen doen. De spullen die je al bezit, geven karakter aan je huis en maken het écht jouw thuis. Mijn doel is om met creatieve en duurzame oplossingen een unieke draai te geven aan elk interieur.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
        <Tablet>
          <Image src="/img/Livaun-interieuradvies-harderwijk.jpg" />
        </Tablet>
        <Item>
          <SubTitle>Interieuradvies aan huis in Harderwijk</SubTitle>
          <StyledParagraph>
            Zoek je interieuradvies aan huis in Harderwijk? Livaun biedt praktische oplossingen voor al je woon- en interieurvragen. Of je interieur nu een opfrisser nodig heeft of je meer gezelligheid en sfeer wilt creëren, ik help je graag persoonlijk verder. Met een advies aan huis kunnen we meteen actie ondernemen. Ik deel graag mijn inzichten en tips om je te inspireren. Dit maakt een kort interieuradvies aan huis ideaal voor elke doe-het-zelver die op zoek is naar dat ene zetje in de juiste richting.
          </StyledParagraph>
        </Item>
        <Item>
          <SubTitle>Interieuradvies bij (ver)bouwprojecten in Harderwijk</SubTitle>
          <StyledParagraph>
            Sta je op het punt te (ver)bouwen in Harderwijk of omstreken en kun je wel wat ondersteuning gebruiken? Bij grote projecten help ik je graag met interieuradvies bij het maken van belangrijke keuzes over de indeling, verlichting, materialen, en uiteraard het interieur. Door vroegtijdig betrokken te zijn, draag ik bij aan het nemen van praktische beslissingen. Ontdek de mogelijkheden voor interieuradvies door een kijkje te nemen op de website.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorAdviceContent>
);

export default InteriorAdviceContentHarderwijk;
