import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorAdviceContent = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin: 30px 0;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const StyledLink = styled.a`
  display: block;
  margin-bottom: 30px;
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorAdviceContent = ({ designScrollRef, onClick }) => (
  <StyledInteriorAdviceContent ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Livaun-interieuradvies-putten.jpg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieuradvies Putten</Title>
          <StyledParagraph>
            Vanuit Livaun bied ik, Marjella, professioneel interieuradvies in Putten en omgeving. Het maakt niet uit of je voorkeur hebt voor landelijk, modern, Scandinavisch, of vintage design, of je nu houdt van een kleurrijk palet of een meer ingetogen stijl; mijn advies is altijd afgestemd op jouw individuele smaak en behoeften.
            <br/>
            <br/>
            Met een scherp oog voor toekomstige trends en ontwikkelingen, benadruk ik met interieuradvies in Putten het belang van duurzaamheid in elk interieurontwerp. In onze consumptiemaatschappij is het mijn visie dat niet alles nieuw hoeft te zijn om je ruimte te transformeren. Ik richt me op het hergebruiken van al het goede dat je graag wilt behouden, om een unieke en persoonlijke sfeer te creëren die echt aanvoelt als thuis. Waar nodig geef ik natuurlijk ook advies voor nieuwe meubels en accessoires.
            <br/>
            <br/>
            Het verplaatsen van meubels of het toepassen van een nieuwe kleur kan al een wereld van verschil maken. Jouw bestaande items brengen persoonlijkheid en warmte in je huis. Met innovatieve en duurzame ideeën streef ik met mijn interieuradvies ernaar om elk interieur uniek en verrassend te maken. Want mijn overtuiging is dat nieuw niet altijd beter is.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
        <Tablet>
          <Image src="/img/Livaun-interieuradvies-putten.jpg" />
        </Tablet>
        <Item>
          <SubTitle>Advies aan huis voor je interieur in Putten</SubTitle>
          <StyledParagraph>
            Op zoek naar interieuradvies bij jou thuis in Putten? Livaun staat voor je klaar om al je interieurvragen te beantwoorden. Of je nu verlangt naar een frisse uitstraling of juist meer gezelligheid wilt brengen in je leefomgeving, ik kom graag langs voor advies aan huis. Met praktische tips en directe suggesties, bied ik de inspiratie die je nodig hebt. Daarnaast kan ik direct helpen met bijvoorbeeld het uitzoeken van kleuren of raamdecoratie. Ideaal voor de doe-het-zelver die zoekt naar inspiratie en het vertrouwen om zelf aan de slag te gaan.
          </StyledParagraph>
        </Item>
        <Item>
          <SubTitle>Interieuradvies voor (ver)bouwprojecten in Putten</SubTitle>
          <StyledParagraph>
            Ga je binnenkort (ver)bouwen in Putten of de omgeving en kun je wel wat hulp gebruiken bij de grote beslissingen? Ik ondersteun je met interieuradvies graag bij het ontwerpen van de juiste indeling, het kiezen van verlichting, materialen en het vormgeven van het interieur. Door vanaf het begin betrokken te zijn, help ik met het maken van de praktische keuzes die jouw (ver)bouwproject naadloos laten aansluiten op het uiteindelijke interieur. Benieuwd naar wat ik voor je kan betekenen? Neem een kijkje op de website.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorAdviceContent>
);

export default InteriorAdviceContent;
