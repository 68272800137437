import React from "react";
import styled from "styled-components";
import Container from "../atoms/Container";
import RouterLink from "../atoms/Link";
import { color } from "../templates/ui";
import MenuCanvas from "./MenuCanvas";
import MenuIcon from "../atoms/icons/MenuIcon";
import Toggle from "../atoms/Toggle";

const StyledHeader = styled.header`
  position: relative;
  padding: 40px 0;
  background-color: #cec6ba;

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  display: block;
  font-size: 18px;
  margin: 0 20px;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    display: none;

    @media only screen and (min-width: 992px) {
      display: block;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: block !important;
`;

const Link = styled(RouterLink)`
  position: relative;
  padding: 5px 0;
  font-family: "Crimson Text", serif;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${color.body.base};
    height: 1px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

const StyledLogo = styled.img`
  width: 150px;
  height: auto;

  @media only screen and (min-width: 992px) {
    width: 283px;
    height: auto;
    margin: -20px 40px 0 40px;
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  width: 23px;
  height: auto;
  fill: ${props => (props.toggle ? "black" : "white")};
`;

const Livaun = styled.h1`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

const StyledContainer = styled(Container)``;

const Header = ({
  aboutOnClick,
  visionOnClick,
  servicesOnClick,
  pricingOnClick,
  buildingOnClick,
  contactOnClick,
  isLandingPage,
  topRef,
  menuOpen,
  toggleMenu,
  href,
  hideMenuToggle
}) => {
  return (
    <>
      <StyledHeader ref={topRef}>
        <StyledContainer>
          <Menu>
            {isLandingPage ? (
              <>
                <StyledMenuItem>
                  <a href="/">
                    <StyledLogo src="/img/livaun-interieur-logo.png" />
                  </a>
                </StyledMenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={aboutOnClick}>
                  <Link href={href ? href : "#"}>over mij</Link>
                </MenuItem>
                <MenuItem onClick={visionOnClick}>
                  <Link href={href ? href : "#"}>mijn visie</Link>
                </MenuItem>
                <MenuItem onClick={servicesOnClick}>
                  <Link href={href ? href : "#"}>wat ik doe</Link>
                </MenuItem>
                <MenuItem>
                  <a href="/">
                    <StyledLogo src="/img/livaun-interieur-logo.png" />
                  </a>
                </MenuItem>
                <MenuItem onClick={pricingOnClick}>
                  <Link href={href ? href : "#"}>advies</Link>
                </MenuItem>
                <MenuItem onClick={buildingOnClick}>
                  <Link href={href ? href : "#"}>(ver)bouwen</Link>
                </MenuItem>
                <MenuItem onClick={contactOnClick}>
                  <Link href="#">contact</Link>
                </MenuItem>
              </>
            )}
          </Menu>
        </StyledContainer>
        {!hideMenuToggle && (
          <Toggle
            onClick={toggleMenu}
            menuOpen={menuOpen}
            isLandingPage={isLandingPage}
          >
            <StyledMenuIcon toggle={toggleMenu} />
          </Toggle>
        )}
      </StyledHeader>
      <MenuCanvas
        containerOpen={menuOpen}
        aboutOnClick={aboutOnClick}
        visionOnClick={visionOnClick}
        servicesOnClick={servicesOnClick}
        pricingOnClick={pricingOnClick}
        buildingOnClick={buildingOnClick}
        contactOnClick={contactOnClick}
        menuOpen={menuOpen}
        toggle={toggleMenu}
      />
      <Livaun>Livaun</Livaun>
    </>
  );
};

export default Header;
