import React, { useRef, useState } from "react";
import Header from "../organisms/Header";
import Contact from "../organisms/Contact";
import { Helmet } from "react-helmet";
import InteriorDesignContent from "../organisms/landingpages/InteriorDesignContent";

const InteriorDesignApeldoorn = ({ history }) => {
  const contactScrollRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const toggleMenu = () => setMenuOpen(prevMenuOpen => !prevMenuOpen);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Op zoek naar een Interieurontwerp in Apeldoorn? | Livaun"
        />
        <meta
          name="description"
          content="Als interieurontwerper in Apeldoorn kan ik je helpen bij een klus- en verbouwplannen of de inrichting van je woning. Een goed interieurontwerp zorgt voor een duidelijk plan."
        />
        <meta name="keywords" content="interieurontwerp apeldoorn" />
        <title>Op zoek naar een Interieurontwerp in Apeldoorn? | Livaun</title>
      </Helmet>
      <Header
        toggleMenu={toggleMenu}
        menuOpen={menuOpen}
        href="/"
        aboutOnClick={() => history.push("/")}
        visionOnClick={() => history.push("/")}
        servicesOnClick={() => history.push("/")}
        pricingOnClick={() => history.push("/")}
        buildingOnClick={() => history.push("/")}
        contactOnClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <InteriorDesignContent
        onClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <Contact contactScrollRef={contactScrollRef} />
    </>
  );
};

export default InteriorDesignApeldoorn;
