import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorStylist = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin: 30px 0;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const StyledLink = styled.a`
  display: block;
  margin-bottom: 30px;
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorStylistContentHarderwijk = ({ designScrollRef, onClick }) => (
  <StyledInteriorStylist ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Livaun-interieurstylist-harderwijk.jpg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieurstylist Harderwijk</Title>
          <StyledParagraph>
            Woonachtig in Harderwijk en op zoek naar persoonlijk advies voor je interieur? Bij Livaun ben je aan het juiste adres. Ik ben Marjella en sinds 2018 werk ik als interieurstylist in Harderwijk.
          </StyledParagraph>
        </Item>
        <Tablet>
          <Image src="/img/Livaun-interieurstylist-harderwijk.jpg" />
        </Tablet>
        <Item>
          <SubTitle>Toegevoegde waarde van een interieurstylist</SubTitle>
          <StyledParagraph>
            Heeft je interieur een mooie basis maar ontbreekt het aan stijl en sfeer? Met mijn gevoel voor kleur, materialen en een scherp oog voor sfeer en creativiteit, breng ik de ontbrekende elementen aan in jouw woonruimte.
            <br />
            <br />
            Het is essentieel voor mij om jouw persoonlijke voorkeuren en leefwijze te begrijpen. Dit zodat ik de inrichting naadloos kan laten aansluiten op jouw smaak, stijl en persoonlijkheid. Ik streef naar een interieur dat 'eigen en vertrouwd' voelt. Mijn missie als <InlineLink href="/">interieurstylist in Harderwijk</InlineLink> om jou te inspireren en je zintuigen te prikkelen, waardoor je zelfverzekerd aan de slag gaat met je interieur!
            <br/>
            <br/>
            Ik ontwikkel een helder plan via een moodboard en shoppingpagina. Het moodboard bepaalt de stijl, sfeer, kleuren en materialen, terwijl de shoppingpagina concrete productadviezen geeft gebaseerd op het moodboard. Zo kun je eenvoudig zelf de meubels en accessoires aanschaffen die je aanspreken. Ook kan ik als interieurstylist je helpen met een 2D indelingsplan en lichtplan voor jouw woonruimte.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
        <Item>
          <SubTitle>Hulp van mij als interieurstylist in Harderwijk</SubTitle>
          <StyledParagraph>
            Ik bied als interieurstylist in Harderwijk zowel advies aan huis met extra uitwerkingen als interieuradvies tijdens (ver)bouwprojecten.
            <br />
            <br />
            Een advies aan huis omvat niet alleen advies over sfeer en stijl, maar ook het realiseren van eenheid en praktische oplossingen. Mijn doel is om jou in korte tijd te inspireren en je te voorzien van de nodige tools om zelfstandig aan de slag te gaan. Indien gewenst, kan ik met behulp van een moodboard, shoppingpagina, 2D indelingsplan of lichtplan laten zien hoe je jouw interieur zelfstandig en op een praktische wijze kunt transformeren.
            <br/>
            <br/>
            Sta je op het punt te (ver)bouwen en wil direct nadenken over het interieur? Als interieurstylist in Harderwijk bied ik uitgebreid interieuradvies voor minimaal één etage. Samen denken we na over de ideale indeling, materiaalkeuzes, verlichting en uiteindelijk natuurlijk kleuren, meubels en accessoires. Door mij vroegtijdig te betrekken, zorgen we ervoor dat alle keuzes in het bouwproces perfect zijn afgestemd op de interieurplannen. Ik inventariseer wat er momenteel ontbreekt in je leefsituatie, waar behoeften liggen en wat jullie ultieme wensen zijn. Zo realiseren we samen een droomhuis dat perfect past bij jullie levensstijl.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorStylist>
);

export default InteriorStylistContentHarderwijk;
