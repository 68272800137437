import React, { useRef, useState } from "react";
import Header from "../organisms/Header";
import Contact from "../organisms/Contact";
import { Helmet } from "react-helmet";
import InteriorStylistContent from "../organisms/landingpages/InteriorStylistContent";

const InteriorStylistApeldoorn = ({ history }) => {
  const contactScrollRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const toggleMenu = () => setMenuOpen(prevMenuOpen => !prevMenuOpen);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Op zoek naar een Interieurstylist in Apeldoorn? | Livaun"
        />
        <meta
          name="description"
          content="Is je interieur in de basis al goed, maar mis je gevoel en sfeer in je interieur? Als interieurstylist in Apeldoorn ben ik gespecialiseerd in kleuren en materialen en beschik je over gevoel voor sfeer en creativiteit."
        />
        <meta
          name="keywords"
          content="interieurstylist apeldoorn, interieurstyling apeldoorn, interieurs stylist apeldoorn"
        />
        <title>Op zoek naar een Interieurstylist in Apeldoorn? | Livaun</title>
      </Helmet>
      <Header
        toggleMenu={toggleMenu}
        menuOpen={menuOpen}
        href="/"
        aboutOnClick={() => history.push("/")}
        visionOnClick={() => history.push("/")}
        servicesOnClick={() => history.push("/")}
        pricingOnClick={() => history.push("/")}
        buildingOnClick={() => history.push("/")}
        contactOnClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <InteriorStylistContent
        onClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <Contact contactScrollRef={contactScrollRef} />
    </>
  );
};

export default InteriorStylistApeldoorn;
