import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorStylist = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin: 30px 0;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorDesignContent = ({ designScrollRef, onClick }) => (
  <StyledInteriorStylist ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Livaun-interieurontwerp-apeldoorn.jpg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieurontwerp Apeldoorn</Title>
          <StyledParagraph>
            Vanuit mijn eigen bedrijf Livaun creëer ik interieurontwerpen in Apeldoorn en omgeving .Een interieurontwerp helpt al jouw ideeën en wensen vorm te geven. Denk aan moodboards met afbeeldingen rondom sfeer en stijl, kleurstalen, materialen en 2D plattegronden voor de indeling en verlichting.
            <br/><br/>
            Soms is het lastig om een inschatting te maken van het totaalplaatje en helpt een interieurontwerp daarbij, vooral als het om grote veranderingen gaat. Woon je in Apeldoorn of omgeving en ga je je woning (her)inrichten of (ver)bouwen?
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
        <Tablet>
          <Image src="/img/Livaun-interieurontwerp-apeldoorn.jpg" />
        </Tablet>
        <Item>
          <SubTitle>Interieurontwerp voor de doe-het-zelver</SubTitle>
          <StyledParagraph>
            Tijdens een advies aan huis zorg ik niet alleen voor sfeer, maar creëer eenheid en praktische oplossingen. Het is mijn doel om jou in korte tijd te inspireren en al je zintuigen te prikkelen, zodat je de tools hebt om zelf aan de slag te gaan. Meer hulp nodig? Door middel van een moodboard, shoppingpagina, 2D indelingsplan of lichtplan kan ik laten zien hoe je jouw interieur - zelfstandig en op een haalbare manier - nieuw leven kunt inblazen.
          </StyledParagraph>
        </Item>
        <Item>
          <SubTitle>Interieurontwerp bij (ver)bouwen in Apeldoorn</SubTitle>
          <StyledParagraph>
            Het is vaak lastig om je voor te stellen hoe een keuze in een ontwerpfase er uiteindelijk uit komt te zien. Omdat de ontelbare mogelijkheden je kunnen overweldigen, kies je logischerwijs vaak de meest bewandelde route. Standaard indelingen voor ruimtes en de daarbij horende lichtpunten en onopvallende materialen en kleuren (omdat er dan nou eenmaal weinig mis kan gaan). En dat is jammer, want wanneer je vanaf nul kunt beginnen, liggen alle mogelijkheden open. Met een goed <InlineLink href="/">interieurontwerp</InlineLink> kun je écht een eigen droomhuis creëren.
            <br />
            <br />
            Een interieurontwerp kan hierbij helpen. Samen maken we een plan dat naadloos aansluit op jouw wensen, gezinssamenstelling, leefwijze en natuurlijk stijl. De indeling, licht, kleur- en materiaalgebruik zijn enorm belangrijk voor de beleving van een ruimte. Dus wil je weten of die muur er wel of niet uit moet? Ik denk graag met je mee! Samen met eventueel een aannemer of architect kunnen we het ontwerp waar nodig optimaliseren. Achteraf aanpassingen doen is namelijk niet alleen onhandig, maar ook erg kostbaar.
            <br />
            <br />
            Heb je net een nieuw huis gekocht in Apeldoorn of heb je (ver)bouwplannen? Ik help je graag! Neem contact met mij op om de mogelijkheden voor een interieurontwerp te bespreken en je wensen in kaart te brengen.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorStylist>
);

export default InteriorDesignContent;
