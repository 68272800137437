import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "./pages/Index";
import InteriorAdviceApeldoorn from "./pages/InteriorAdviceApeldoorn";
import InteriorStylistApeldoorn from "./pages/InteriorStylistApeldoorn";
import Overview from "./pages/Overview";
import InteriorDesignApeldoorn from "./pages/InteriorDesignApeldoorn";
import InteriorAdvicePutten from "./pages/InteriorAdvicePutten";
import InteriorStylistPutten from "./pages/InteriorStylistPutten";
import InteriorDesignPutten from "./pages/InteriorDesignPutten";
import InteriorAdviceHarderwijk from "./pages/InteriorAdviceHarderwijk";
import InteriorStylistHarderwijk from "./pages/InteriorStylistHarderwijk";
import InteriorDesignHarderwijk from "./pages/InteriorDesignHarderwijk";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route
          exact
          path="/interieuradvies-apeldoorn"
          component={InteriorAdviceApeldoorn}
        />
        <Route
          exact
          path="/interieurstylist-apeldoorn"
          component={InteriorStylistApeldoorn}
        />
        <Route
          exact
          path="/interieurontwerp-apeldoorn"
          component={InteriorDesignApeldoorn}
        />
        <Route
          exact
          path="/interieuradvies-harderwijk"
          component={InteriorAdviceHarderwijk}
        />
        <Route
          exact
          path="/interieurstylist-harderwijk"
          component={InteriorStylistHarderwijk}
        />
        <Route
          exact
          path="/interieurontwerp-harderwijk"
          component={InteriorDesignHarderwijk}
        />
        <Route
          exact
          path="/interieuradvies-putten"
          component={InteriorAdvicePutten}
        />
        <Route
          exact
          path="/interieurstylist-putten"
          component={InteriorStylistPutten}
        />
        <Route
          exact
          path="/interieurontwerp-putten"
          component={InteriorDesignPutten}
        />
        <Route exact path="/interieuradvies-livaun" component={Overview} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
