import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorStylist = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin: 30px 0;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorDesignContentHarderwijk = ({ designScrollRef, onClick }) => (
  <StyledInteriorStylist ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Livaun-interieurontwerp-harderwijk.jpg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieurontwerp Harderwijk</Title>
          <StyledParagraph>
            Bij Livaun creëer ik, Marjella, een prachtig interieurontwerp voor jou in Harderwijk en omgeving. Een interieurontwerp brengt al jouw dromen en wensen tot leven. Van inspirerende moodboards tot een doordachte 2D plattegrond en lichtplan voor een perfecte indeling en sfeervolle verlichting.
            <br/>
            <br/>
            Woon je in Harderwijk en wil je aan de slag met je interieur? Zeker bij grote veranderingen biedt een helder interieurontwerp uitkomst. Zo weet je precies wat je moet doen en wat er nodig is om jouw interieur nieuw leven in te blazen!
          </StyledParagraph>
        </Item>
        <Item>
          <SubTitle>Zelf aan de slag met interieurontwerp</SubTitle>
          <StyledParagraph>
            Ik bied <InlineLink href="/">advies aan huis</InlineLink> dat verder gaat dan alleen een mooi plaatje. Ik bedenk oplossingen die écht werken. In een kort bezoek inspireer ik je om verder te kijken en misschien zelfs wel om keuzes te maken die je zelf nooit zou durven maken. Voor wie graag net wat meer ondersteuning wil, werk ik naderhand naar wens graag een moodboard, shoppinglijst, 2D indelingsplan of lichtplan uit. Zo kun je helemaal zelfstandig je interieur transformeren.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
        <Item>
          <SubTitle>Interieurontwerp voor jouw (ver)bouwproject in Harderwijk</SubTitle>
          <StyledParagraph>
            Het visualiseren van een nieuw ontwerp kan overweldigend zijn door de vele keuzes. Vaak kies je al snel voor veilige, standaard oplossingen. Maar dat is jammer; juist nu alle opties open liggen. Ik laat zien hoe je een uniek interieur creëert dat helemaal past bij wat jij mooi vindt.
            <br />
            <br />
            Ik help je een interieurontwerp te ontwikkelen dat perfect aansluit bij jouw persoonlijke wensen, levensstijl en voorkeuren. Samen nemen we beslissingen over indeling, verlichting, kleur en materialen die de sfeer van je huis bepalen. En mocht het nodig zijn, werk ik samen met jouw aannemer of architect om het ontwerp naadloos te laten aansluiten op jouw manier van leven. Aanpassingen achteraf vermijden, bespaart niet alleen tijd maar ook geld.
            <br />
            <br />
            Sta je aan de start van een nieuw hoofdstuk in Harderwijk met een (ver)bouwproject? Met een interieurontwerp help ik je verder. Neem contact op om jouw interieurwensen te bespreken en de mogelijkheden te verkennen.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
        <Tablet>
          <Image src="/img/Livaun-interieurontwerp-harderwijk.jpg" />
        </Tablet>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorStylist>
);

export default InteriorDesignContentHarderwijk;
