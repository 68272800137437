import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorAdviceContent = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin-top: 30px;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorAdviceContent = ({ designScrollRef, onClick }) => (
  <StyledInteriorAdviceContent ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Livaun-interieuradvies-apeldoorn.jpg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieuradvies Apeldoorn</Title>
          <StyledParagraph>
            Vanuit mijn eigen bedrijf Livaun geef ik interieuradvies in Apeldoorn en omgeving. Of je nu een landelijk, modern, Scandinavisch of vintage interieur hebt, kleurrijk of juist niet; het advies wordt helemaal aangepast op jouw visie, wensen en persoonlijke stijl.
          </StyledParagraph>
          <br />
          <StyledParagraph>
            Als interieurstylist kijk ik veel naar de toekomst en nieuwe ontwikkelingen. Bewust omgaan met materialen is erg belangrijk voor mij. Zeker in een wereld waar alles voorhanden is. Een nieuw interieur betekent voor mij niet dat alles nieuw hoeft te zijn. Daarom kijk ik bewust naar hoe bestaande items hergebruikt kunnen worden.
          </StyledParagraph>
          <br />
          <StyledParagraph>
            Vaak kan een andere plek of een likje verf al genoeg zijn. Juist de items die je al hebt zorgen voor een persoonlijk interieur waarin jij je helemaal thuis voelt. Slimme oplossingen en hergebruik zorgen voor verrassing en eigenheid. Daar streef ik naar!
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
        <Tablet>
          <Image src="/img/Livaun-interieuradvies-apeldoorn.jpg" />
        </Tablet>
        <Item>
          <SubTitle>Interieuradvies aan huis in Apeldoorn</SubTitle>
          <StyledParagraph>
            Woon je in Apeldoorn en ben je op zoek naar <InlineLink href="/">interieuradvies aan huis</InlineLink>? Met interieuradvies bij Livaun krijg je antwoord op al je woon- en interieurvragen. Misschien is je huidige interieur toe aan vernieuwing of mis je vooral gezelligheid en sfeer in huis. Wat je vraag ook is, ik kom graag bij je langs om je op weg te helpen. Door middel van een advies aan huis kunnen we samen direct aan de slag. Tijdens deze afspraak geef ik handige tips om jou te inspireren. Interieuradvies aan huis is geschikt voor iedere doe-het-zelver die nét dat ene duwtje in de juiste richting nodig heeft.
          </StyledParagraph>
        </Item>
        <Item>
          <SubTitle>Interieuradvies tijdens (ver)bouwen in Apeldoorn</SubTitle>
          <StyledParagraph>
            Ga je (ver)bouwen in Apeldoorn of omgeving en kun je wel wat extra hulp gebruiken? Ook bij grote bouw- of verbouwplannen (vanaf één etage) kan ik je met interieuradvies helpen om na te denken over de juiste indeling, verlichting, materiaalkeuzes en uiteindelijk natuurlijk het interieur. Door al in een vroeg stadium aan te haken, kan ik direct meedenken over alle praktische keuzes die gemaakt moeten worden.  Benieuwd naar de mogelijkheden voor interieuradvies?
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorAdviceContent>
);

export default InteriorAdviceContent;
