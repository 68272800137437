import React, { useRef, useState } from "react";
import Header from "../organisms/Header";
import Contact from "../organisms/Contact";
import { Helmet } from "react-helmet";
import InteriorStylistContentPutten from "../organisms/landingpages/InteriorStylistContentPutten";

const InteriorStylistPutten = ({ history }) => {
  const contactScrollRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const toggleMenu = () => setMenuOpen(prevMenuOpen => !prevMenuOpen);

  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Op zoek naar een Interieurstylist in Putten? | Livaun"
        />
        <meta
          name="description"
          content="Woon je in Putten en kun je mijn hulp gebruiken bij de styling van je interieur? Ik help je graag. Met interieurstyling zorg ik voor meer  sfeer en eigenheid."
        />
        <meta
          name="keywords"
          content="interieurstylist putten, interieurstyling putten, interieurs stylist putten"
        />
        <title>Op zoek naar een Interieurstylist in Putten? | Livaun</title>
      </Helmet>
      <Header
        toggleMenu={toggleMenu}
        menuOpen={menuOpen}
        href="/"
        aboutOnClick={() => history.push("/")}
        visionOnClick={() => history.push("/")}
        servicesOnClick={() => history.push("/")}
        pricingOnClick={() => history.push("/")}
        buildingOnClick={() => history.push("/")}
        contactOnClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <InteriorStylistContentPutten
        onClick={() =>
          contactScrollRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        }
      />
      <Contact contactScrollRef={contactScrollRef} />
    </>
  );
};

export default InteriorStylistPutten;
