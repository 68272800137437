import React from "react";
import styled from "styled-components";
import Paragraph from "../../atoms/Paragraph";
import { color } from "../../templates/ui";
import Container from "../../atoms/Container";
import Responsive from "react-responsive";

const StyledInteriorStylist = styled.div`
  padding: 70px 0 40px;
  background-color: ${color.body.base};

  @media only screen and (min-width: 992px) {
    padding: 80px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 20px;

  @media only screen and (min-width: 992px) {
    display: block;
    width: 440px;
    height: 580px;
    object-fit: unset;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1800px) {
    width: 456px;
    height: 533px;
  }
`;

const Title = styled.h1`
  position: relative;
  display: block;
  font-size: 30px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 700;
  color: #cdb8a8;
  margin-top: 0;
  margin-bottom: 20px;
  max-width: 416px;
  text-transform: uppercase;
  line-height: 2.4rem;

  span {
    font-weight: 700;
    display: block;
  }

  @media only screen and (min-width: 992px) {
    font-size: 48px;
    line-height: 3.5rem;
  }
`;

const SubTitle = styled.h2`
  font-family: "MyanmarMN Webfont",sans-serif;
  line-height: 1.9rem;
  font-size: 24px;
  font-weight: 700;
  color: #d1b7a6;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const Item = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #d1b7a6;
  font-size: 18px;

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex-basis: 50%;
`;

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  width: auto;
  height: 60px;
  padding: 0 20px;
  margin: 30px 0;
  font-size: 16px;
  font-family: "MyanmarMN Webfont", sans-serif;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  background-color: #9d735a;
  color: white;

  &:hover {
    background-color: #8d6751;
  }
`;

const StyledLink = styled.a`
  display: block;
  margin-bottom: 30px;
`;

const InlineLink = styled.a`
  color: #d1b7a6;

  &:hover {
    text-decoration: none;
  }
`;

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

const InteriorStylistContent = ({ designScrollRef, onClick }) => (
  <StyledInteriorStylist ref={designScrollRef}>
    <StyledContainer compact>
      <Desktop>
        <Image src="/img/Livaun-interieurstylist-apeldoorn.jpg" />
      </Desktop>
      <TextWrapper>
        <Item>
          <Title>Interieurstylist in Apeldoorn</Title>
          <StyledParagraph>
            Woon je in Apeldoorn en kun je wel wat hulp gebruiken met de styling van je interieur? Dan zit je bij Livaun goed. Mijn naam is Marjella. Ik ben afgestudeerd Allround Stylist aan Academie Artemis in Amsterdam en werk sinds 2018 vanuit mijn eigen bedrijf Livaun als interieurstylist in Apeldoorn.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Bekijk de mogelijkheden</StyledButton>
          </a>
        </Item>
        <Tablet>
          <Image src="/img/Livaun-interieurstylist-apeldoorn.jpg" />
        </Tablet>
        <Item>
          <SubTitle>Toegevoegde waarde van een interieurstylist</SubTitle>
          <StyledParagraph>
            Is je interieur in de basis al goed, maar mis je stijl en sfeer in je interieur? Als interieurstylist ben ik gespecialiseerd in kleuren en materialen en beschik ik over gevoel voor sfeer en creativiteit.
            <br />
            <br />
            Ik vind het belangrijk om mij te verdiepen in jou als bewoner, om de styling van jouw interieur perfect te kunnen afstemmen op jouw smaak, stijl en persoonlijkheid. Ik verplaats mij in je leefsituatie en stem de styling van het interieur daarop af. Bij Livaun is het allerbelangrijkste uiteindelijk dat je interieur ‘eigen en vertrouwd’ voelt. Het is mijn doel om als interieurstylist jou te inspireren en al je zintuigen te prikkelen, zodat je het vertrouwen krijgt om ook zelf aan de slag te gaan!
            <br />
            <br />
            Door middel van een moodboard en shoppingpagina werk als interieurstylist in Apeldoorn voor jullie een duidelijk plan uit. Het moodboard voor de stijl, sfeer, kleuren en materialen. De shoppingpagina is een concreet product advies gebaseerd op het moodboard. Vanuit de shoppingpagina kun je de meubels en accessoires die je aanspreken gemakkelijk zelf aanschaffen.
          </StyledParagraph>
        </Item>
        <Item>
          <SubTitle>Mijn werk als interieurstylist in Apeldoorn</SubTitle>
          <StyledParagraph>
            Als <InlineLink href="/">interieurstylist in Apeldoorn</InlineLink> bied ik advies aan huis (met extra uitwerkingen) of interieuradvies tijdens (ver)bouwen.
            <br /><br/>
            Tijdens een advies aan huis zorg ik niet alleen voor sfeer, maar creëer eenheid en praktische oplossingen. Het is mijn doel om jou in korte tijd te inspireren en al je zintuigen te prikkelen, zodat je de tools hebt om zelf aan de slag te gaan. Meer hulp nodig? Door middel van een moodboard, shoppingpagina, 2D indelingsplan of lichtplan kan ik laten zien hoe je jouw interieur - zelfstandig en op een haalbare manier - nieuw leven kunt inblazen.
          </StyledParagraph>
        </Item>
        <Item>
          <SubTitle>Verbouwen in Apeldoorn?</SubTitle>
          <StyledParagraph>
            Ga je (ver)bouwen en wil je direct nadenken over het interieur? Ik geef uitgebreid interieuradvies in de omgeving van Apeldoorn vanaf één etage. Ik kan je helpen om na te denken over de juiste indeling, materiaalkeuzes, verlichting en uiteindelijk natuurlijk het interieur. Door mij in een vroeg stadium te laten aanhaken, zorgen we ervoor dat alle keuzes in het bouwproces nauwkeurig zijn afgestemd op de plannen voor het interieur. Ik breng in kaart wat jullie in je huidige leefsituatie missen, waar behoefte aan is en wat jullie allergrootste wensen zijn. Zo creëren we samen een droomhuis dat helemaal aansluit bij jouw manier van leven.
          </StyledParagraph>
          <a href="/">
            <StyledButton>Kan ik je helpen?</StyledButton>
          </a>
        </Item>
      </TextWrapper>
    </StyledContainer>
  </StyledInteriorStylist>
);

export default InteriorStylistContent;
